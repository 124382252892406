<template>
    <div class="mask">
            <a-page-header
      style=" border: 1px solid rgb(235, 237, 240); background: #fff"
    >
      <template slot="extra">
    <a-button type="link" icon='eye'
                                  @click="SubmitModel('ruleForm', 0)"
                                  :loading="loading">预览</a-button>


                        <a-button type="primary"
                                  @click="SubmitModel('ruleForm', 1)"
                                  :disabled="disabled"
                                  :loading="loading">推送</a-button>

                        <a-button @click="close(true)">取消</a-button>
      </template>
    </a-page-header>
        <div class="push-info">
            <a-row style="height: 100%">

                <a-col :span='1' style="background: #fff;height:100%">
                    <div class="tool-bar">
                        <a @click="toggleshow"><i class="el-icon-s-grid" style="font-size:24px;"></i><br />模板</a>
                        <a @click="Addtext"><i class="el-icon-plus" style="font-size:24px;"></i><br />文字</a>
                        <!--<a @click="Addimg"><i class="el-icon-picture" style="font-size:24px;"></i><br />图片</a>-->

                        <a>
                            <a-upload action="/FileServer/Upload"
                                      :data="ParamData"
                                      :multiple="true"
                                      :file-list="fileList"
                                      :showUploadList="false"
                                      @change="handleChange">
                                <i class="el-icon-picture" style="font-size:24px;"></i><br />图片
                                <!--<a-button> <i class="el-icon-picture" style="font-size:24px;"></i><br />图片</a-button>-->
                            </a-upload>
                        </a>
                    </div>

                </a-col>
                <a-col v-show='showmb' :span="showmb ? 3 : 0" :style="ID == 0? 'background:#fff;padding: 10px; height: 100%;': ''">
                    <div>

                        <a-row :gutter="[10, 10]">
                            <a-col :span="12"
                                   v-for="info in tableData"
                                   :key="info.ID"
                                   class="mtoptitleCont">
                                <div :key="info.ID"
                                     @click="ShowIframe(info)"
                                     v-bind:class="{ active: info.ID == tmplid }"
                                     style="height: 100%">
                                    <img slot="cover"
                                         class="thumb-img"
                                         :src="Getsrc(info)"
                                         style="width: 100%; height: 100%; object-fit: cover" />
                                    <div class="mtop-title">{{ info.Name }}</div>
                                    <div class="mtop-but">使用模板</div>
                                    {{ TimeStamptoDateTime(info.InputTime) }}
                                </div>
                            </a-col>
                        </a-row>

                        <a-row>

                            <a-col align="center" :span="24">
                                <a-pagination v-model="current"
                                              :total="total"
                                              size="small"
                                              :defaultPageSize="defaultPageSize"
                                              :showTotal="showTotal"
                                              @change="pagerChange" />
                            </a-col>
                        </a-row>
                    </div>
                 
                </a-col>
                <a-col :span="showmb ? 15 : 18" class="phone-pre">
                    <!-- <el-button type="primary" plain @click="Addtext">添加文本</el-button>
                    <el-button type="success" plain @click="Addimg">添加图片</el-button> -->
                   
                 
                    <div class="phone-frame" :style="{transform:'scale('+phoneSize/100+')'}" :title='phoneSize'>

                        <iframe id="MyIframe"
                                width="100%"
                                height="100%"
                                :src="GetIframe()"
                                frameborder="0"></iframe>
                    </div>
                       <div class="zoomBar"> <el-input-number v-model="phoneSize" size="small" @change="changeSize" :min="2" :step="20" :max="120" label="缩放画板">    </el-input-number></div>
                </a-col>
                <a-col :span="5"
                       style="
            background: #fff;
            height: 100%;
            overflow-y: auto;

          ">
                    

                    <a-form-model layout="horizontal"
                                  style="padding: 10px; height: auto"
                                  :rules="rules"
                                  ref="ruleForm"
                                  :model="Model">
                        <a-tabs v-model="TabsKey" style="text-align:left">
                            <a-tab-pane key="0" tab="编辑" style="padding: 20px;text-align:left">
                                <div v-if="H5Type == 0">
                                    <div class="editor-item">
                                        <h4>切换字体</h4>
                                        <a-row>
                                            <a-col :span="7">
                                                <a-dropdown :trigger="['click']" :disabled="disabled">
                                                    <a-button class="ant-dropdown-link"
                                                              @click="(e) => e.preventDefault()"
                                                              :key="fontname">
                                                        <img :src="GetFontsrc(fontthumb)"
                                                             style="width: 110px; height: 30px" /><a-icon type="down" />
                                                    </a-button>
                                                    <a-menu slot="overlay" class="FontListCss">
                                                        <template v-for="item in FontList">
                                                            <a-menu-item :key="item.name">
                                                                <a @click="changefont(item)">
                                                                    <img :src="GetFontsrc(item['font-thumb'])"
                                                                         style="width: 110px; height: 30px" />
                                                                </a>
                                                            </a-menu-item>
                                                        </template>
                                                    </a-menu>
                                                </a-dropdown>
                                            </a-col>
                                            <a-col :span="3">
                                                <a-input-number :min="8"
                                                                :max="80"
                                                                v-model="fontsize"
                                                                :disabled="disabled"
                                                                @change="applydata" />
                                            </a-col>
                                        </a-row>
                                    </div>
                                    <div class="editor-item">
                                        <h4>字体颜色</h4>
                                        <el-color-picker v-model="color"
                                                         @change="applydata"
                                                         :disabled="disabled"></el-color-picker>
                                    </div>

                                    <div class="editor-item">
                                        <h4>字体样式</h4>
                                        <div class="btn-g">
                                            <a-tooltip placement="bottom">
                                                <template #title>
                                                    <span>加粗</span>
                                                </template>
                                                <a-button size="large"
                                                          :class="{ fontclickcss: fontweight }"
                                                          @click="fontclick('fontweight')"
                                                          style="color: black"
                                                          type="link"
                                                          :disabled="disabled">B</a-button>
                                            </a-tooltip>

                                            <a-tooltip placement="bottom">
                                                <template #title>
                                                    <span>斜体</span>
                                                </template>
                                                <a-button size="large"
                                                          :class="{ fontclickcss: fontstyle }"
                                                          @click="fontclick('fontstyle')"
                                                          style="color: black"
                                                          type="link"
                                                          :disabled="disabled">/</a-button>
                                            </a-tooltip>
                                            <a-tooltip placement="bottom">
                                                <template #title>
                                                    <span>下划线</span>
                                                </template>
                                                <a-button size="large"
                                                          :class="{ fontclickcss: underline }"
                                                          @click="fontclick('underline')"
                                                          style="color: black"
                                                          type="link"
                                                          :disabled="disabled">U</a-button>
                                            </a-tooltip>
                                            <a-tooltip placement="bottom">
                                                <template #title>
                                                    <span>删除线</span>
                                                </template>
                                                <a-button size="large"
                                                          :class="{ fontclickcss: linethrough }"
                                                          @click="fontclick('linethrough')"
                                                          style="color: black"
                                                          type="link"
                                                          :disabled="disabled">—</a-button>
                                            </a-tooltip>
                                        </div>
                                    </div>


                                    <div class="editor-item">
                                        <h4>编辑内容</h4>
                                        <a-input v-model="H5Value"
                                                 type="textarea"
                                                 style="
                        outline: none;
                        max-height: 350px;
                        min-height: 100px;
                      "
                                                 onkeyup="this.value=this.value.replace(/\s+/g,'')"
                                                 :disabled="disabled"
                                                 @change="applydata"></a-input>
                                    </div>
                                </div>
                                <Rich v-bind:value.sync="H5Value"
                                      rows="800"
                                      :disabled="disabled"
                                      @update:value="applydata"
                                      v-if="H5Type == 1"></Rich>
                                <div v-if="H5Type == 2">
                                    <p> 更改图片</p>
                                    <a-upload name="image"
                                              :data="ParamData"
                                              list-type="picture-card"
                                              class="avatar-uploader"
                                              action="/FileServer/Upload"
                                              :fileList="fileList"
                                              @change="imgChangeH5"
                                              :disabled="disabled">
                                        <div v-if="fileList.length < 1">
                                            <a-icon type="plus" />
                                            <div class="ant-upload-text">上传图片</div>
                                        </div>
                                    </a-upload>
                                    <p> 图片尺寸</p>

                                    <a-input-number :min="16"
                                                    v-model="imgwidth"
                                                    :disabled="disabled"
                                                    @change="applydata" />
                                    X
                                    <a-input-number :min="16"
                                                    v-model="imgheight"
                                                    :disabled="disabled"
                                                    @change="applydata" />像素
                                </div>
                                <a-dropdown :trigger="['click']" v-if="H5Type == 3">
                                    <a class="ant-dropdown-link"
                                       @click="(e) => e.preventDefault()"
                                       :key="Material.val">
                                        {{ Material.key }}<a-icon type="down" />
                                    </a>
                                    <a-menu slot="overlay">
                                        <template v-for="item in MaterialList">
                                            <a-menu-item :key="item.val">
                                                <a @click="SetShowKey(item)">{{ item.key }}</a>
                                            </a-menu-item>
                                        </template>
                                    </a-menu>
                                </a-dropdown>
                            </a-tab-pane>
                            <a-tab-pane key="1" tab="传播推送" style="padding:0 20px;text-align:left">
                                <a-row type="flex"
                                       justify="space-around"
                                       style="  padding: 10px">
                                    <a-col :span="17">
                                        <a-form-model-item label="标题"
                                                           labelAlign="left"
                                                           style="margin-bottom: 10px"
                                                           :label-col="formItemLayout.labelCol"
                                                           :wrapper-col="formItemLayout.wrapperCol"
                                                           prop="Name">
                                            <a-input placeholder="标题"
                                                     v-model="Model.Name"
                                                     style="outline: none"
                                                     onkeyup="this.value=this.value.replace(/\s+/g,'')"
                                                     :disabled="disabled"
                                                     :maxLength="100"
                                                     @change="name_change"></a-input>
                                        </a-form-model-item>
                                        <div class="line"></div>
                                        <!-- <a-form-model-item label="作者"
                                                           labelAlign="left"
                                                           style="margin-bottom: 10px"
                                                           :label-col="formItemLayout.labelCol"
                                                           :wrapper-col="formItemLayout.wrapperCol"
                                                           prop="Author">
                                            <a-input placeholder="作者"
                                                     type="input"
                                                     rows="6"
                                                     v-model="Model.Author"
                                                     onkeyup="this.value=this.value.replace(/\s+/g,'')"
                                                     :disabled="disabled"
                                                     :maxLength="50"
                                                     style="outline: none"></a-input>
                                        </a-form-model-item> -->
                                        <a-form-model-item label="分享描述"
                                                           labelAlign="left"
                                                           style="margin-bottom: 10px"
                                                           :label-col="formItemLayout.labelCol"
                                                           :wrapper-col="formItemLayout.wrapperCol"
                                                           prop="Summary">
                                            <a-input placeholder="分享描述"
                                                     type="textarea"
                                                     v-model="Model.Summary"
                                                     onkeyup="this.value=this.value.replace(/\s+/g,'')"
                                                     :maxLength="20"
                                                     :disabled="disabled"
                                                     style="outline: none"></a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col style="margin: 0 auto" :span="5">
                                        <a-upload name="image"
                                                  :data="ParamData"
                                                  list-type="picture-card"
                                                  class="avatar-uploader"
                                                  action="/FileServer/Upload"
                                                  :fileList="Model.fileList"
                                                  @change="imgChange"
                                                  :disabled="disabled">
                                            <div v-if="Model.fileList.length < 1">
                                                <a-icon type="plus" />
                                                <div class="ant-upload-text">缩略图</div>
                                            </div>
                                        </a-upload>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col :span="24" style="display:none;">
                                        <a-form-model-item label="分享时间"
                                                           labelAlign="left"
                                                           :label-col="formItemLayout.labelCol"
                                                           :wrapper-col="formItemLayout.wrapperColline"
                                                           prop="ShareTime">
                                            <a-date-picker v-model="Model.ShareTime"
                                                           style="width: 100%"
                                                           show-time
                                                           type="date"
                                                           format="YYYY-MM-DD HH:mm"
                                                           placeholder="选择开始时间"
                                                           :disabled="disabled" />
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                            </a-tab-pane>
                            <!--<a-tab-pane key="2" tab="传播激励" style="padding: 20px">
                                <a-form-model-item label="开始时间" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline"
                                                   prop="BeginTime">
                                    <a-date-picker v-model="Model.MaterialAward.BeginTime"
                                                   style="width: 100%"
                                                   show-time
                                                   type="date"
                                                   :disabled-date="disabledStartDate"
                                                   format="YYYY-MM-DD HH:mm:ss"
                                                   placeholder="选择开始时间"
                                                   @openChange="handleStartOpenChange"
                                                   :disabled="disabled" />
                                </a-form-model-item>
                                <a-form-model-item label="结束时间" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline"
                                                   prop="EndTime">
                                    <a-date-picker show-time
                                                   style="width: 100%"
                                                   type="date"
                                                   :disabled-date="disabledEndDate"
                                                   format="YYYY-MM-DD HH:mm:ss"
                                                   placeholder="选择结束时间"
                                                   :open="endOpen"
                                                   @openChange="handleEndOpenChange"
                                                   v-model="Model.MaterialAward.EndTime"
                                                   :disabled="disabled" />
                                </a-form-model-item>
                                <a-form-model-item label="位置区域" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-switch v-model="Model.MaterialAward.GPSLimit"
                                              :disabled="disabled">
                                        <a-icon slot="checkedChildren" type="check" />
                                        <a-icon slot="unCheckedChildren" type="close" />
                                    </a-switch>
                                    <a-button type="primary"
                                              value="点击选择"
                                              @click="map_display"
                                              v-show="Model.MaterialAward.GPSLimit"
                                              size="small"
                                              style="margin-left: 12px"
                                              :disabled="disabled">点击选择</a-button>
                                </a-form-model-item>
                                <a-form-model-item label="地址" labelAlign='left'
                                                   v-show="Model.MaterialAward.GPSLimit"
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-input placeholder="地址"
                                             type="textarea"
                                             disabled
                                             v-model="mapModel.tempaddress" />
                                </a-form-model-item>
                                <a-form-model-item label="浏览时长(秒)"
                                                   prop="ReadSecond" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-input-number :min="1"
                                                    :step="1"
                                                    v-model="Model.MaterialAward.ReadSecond"
                                                    :disabled="disabled" />
                                </a-form-model-item>
                                <a-form-model-item has-feedback
                                                   prop="ReadMoney"
                                                   label="阅读金额" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-input-number :min="0"
                                                    :step="0"
                                                    v-model="Model.MaterialAward.ReadMoney"
                                                    :disabled="disabled"
                                                    :precision="2" />
                                </a-form-model-item>
                                <a-form-model-item has-feedback
                                                   prop="PersonMaxMoney"
                                                   label="单人最高金额" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-input-number :min="0"
                                                    :step="0"
                                                    v-model="Model.MaterialAward.PersonMaxMoney"
                                                    :disabled="disabled"
                                                    :precision="2" />
                                </a-form-model-item>
                                <a-form-model-item has-feedback
                                                   prop="BudgetMoney"
                                                   label="活动最高金额" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-input-number :min="0"
                                                    :step="0"
                                                    v-model="Model.MaterialAward.BudgetMoney"
                                                    :disabled="disabled"
                                                    :precision="2" />
                                </a-form-model-item>
                            </a-tab-pane>-->
                            <a-tab-pane key="3" tab="优惠卷" style="padding: 20px" v-if="AwardPooldisplay">
                                <a-button @click="showAwardPool()"
                                          :disabled="disabled"
                                          icon="plus">添加优惠券</a-button>
                                <div style="margin-top: 15px; width: 100%">
                                    <a-row v-for="item in Model.AwardPool"
                                           :key="item.AwardPoolID"
                                           :gutter="[10, 10]"
                                           style="border-bottom: 1px solid #f9f9f9">
                                        <a-col :span="5">
                                            <img :src="Getsrc2(item)"
                                                 style="object-fit: cover"
                                                 width="80" />
                                        </a-col>
                                        <a-col :span="16"><strong>{{ item.Name }}</strong></a-col>
                                        <a-col :span="3">
                                            <a-button size="small"
                                                      type="danger"
                                                      @click="deleteAwardPool(item.AwardPoolID)"
                                                      :disabled="disabled">删除</a-button>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-tab-pane>
                            <!--<a-tab-pane key="4" tab="推广奖励" style="padding: 20px">
                                <a-form-model-item label="开始时间" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline"
                                                   prop="BeginTime">
                                    <a-date-picker v-model="Model.MaterialPromoteAwardConfig.BeginTime"
                                                   style="width: 100%"
                                                   show-time
                                                   type="date"
                                                   :disabled-date="disabledPromoteStartDate"
                                                   format="YYYY-MM-DD HH:mm:ss"
                                                   placeholder="选择开始时间"
                                                   @openChange="handlePromoteStartOpenChange"
                                                   :disabled="disabled" />
                                </a-form-model-item>
                                <a-form-model-item label="结束时间" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline"
                                                   prop="EndTime">
                                    <a-date-picker show-time
                                                   style="width: 100%"
                                                   type="date"
                                                   :disabled-date="disabledPromoteEndDate"
                                                   format="YYYY-MM-DD HH:mm:ss"
                                                   placeholder="选择结束时间"
                                                   :open="PromoteendOpen"
                                                   @openChange="handlePromoteEndOpenChange"
                                                   v-model="Model.MaterialPromoteAwardConfig.EndTime"
                                                   :disabled="disabled" />
                                </a-form-model-item>
                                <a-form-model-item has-feedback prop="AwardType" label="奖励类型" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-radio-group v-model="Model.MaterialPromoteAwardConfig.AwardType" :disabled="disabled">
                                        <a-radio :value="0">
                                            红包
                                        </a-radio>
                                        <a-radio :value="1">
                                            优惠券
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-model-item>
                                <a-form-model-item has-feedback
                                                   prop="LuckMoney"
                                                   label="奖励金额" labelAlign='left'
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline" v-if="Model.MaterialPromoteAwardConfig.AwardType==0">
                                    <a-input-number :min="0"
                                                    :step="0"
                                                    v-model="Model.MaterialPromoteAwardConfig.LuckMoney"
                                                    :disabled="disabled"
                                                    :precision="2" />
                                </a-form-model-item>
                                <div v-if="Model.MaterialPromoteAwardConfig.AwardType==1">

                                    <a-row>
                                        <a-col :span="24">
                                            <a-button @click="showAwardPoolConfig()"
                                                      :disabled="disabled"
                                                      icon="plus">添加优惠券</a-button>
                                        </a-col>
                                    </a-row>


                                    <a-row v-for="item in Model.MaterialPromoteAwardConfig.MaterialAwardPool" :key="item.AwardPoolID" :gutter="[10,10]" style="border-bottom:1px solid #f9f9f9;margin-top:25px">
                                        <a-col :span="5"> <img :src="Getsrc2(item)" style="object-fit:cover" width="80" /></a-col>
                                        <a-col :span="16"><strong>{{ item.Name }}</strong></a-col>
                                        <a-col :span="3"><a-button size="small" type="danger" @click="deleteAwardPoolConfig(item.AwardPoolID)" :disabled="disabled">删除</a-button></a-col>
                                    </a-row>

                                </div>
                                <div style="margin-top:35px">


                                    <a-form-model-item label="领取优惠券人数"
                                                       prop="GetAwardCount" labelAlign='left'
                                                       :label-col="formItemLayout.labelCol"
                                                       :wrapper-col="formItemLayout.wrapperColline">
                                        <a-input-number :min="0"
                                                        :step="0"
                                                        v-model="Model.MaterialPromoteAwardConfig.GetAwardCount"
                                                        :disabled="disabled" />
                                    </a-form-model-item>
                                    <a-form-model-item label="购买商品人数"
                                                       prop="BuyGoodsCount" labelAlign='left'
                                                       :label-col="formItemLayout.labelCol"
                                                       :wrapper-col="formItemLayout.wrapperColline">
                                        <a-input-number :min="0"
                                                        :step="0"
                                                        v-model="Model.MaterialPromoteAwardConfig.BuyGoodsCount"
                                                        :disabled="disabled" />
                                    </a-form-model-item>
                                    <a-form-model-item label="阅读人数"
                                                       prop="ReadCount" labelAlign='left'
                                                       :label-col="formItemLayout.labelCol"
                                                       :wrapper-col="formItemLayout.wrapperColline">
                                        <a-input-number :min="0"
                                                        :step="0"
                                                        v-model="Model.MaterialPromoteAwardConfig.ReadCount"
                                                        :disabled="disabled" />
                                    </a-form-model-item>
                                    <a-form-model-item label="奖励最大次数"
                                                       prop="AwardCount" labelAlign='left'
                                                       :label-col="formItemLayout.labelCol"
                                                       :wrapper-col="formItemLayout.wrapperColline">
                                        <a-input-number :min="1"
                                                        :step="1"
                                                        v-model="Model.MaterialPromoteAwardConfig.AwardCount"
                                                        :disabled="disabled" />
                                    </a-form-model-item>
                                </div>
                            </a-tab-pane>-->
                            <a-tab-pane key="5" tab="视频" style="padding: 20px" v-if="Videodisplay">
                                <a-button @click="showVideo()"
                                          :disabled="disabled"
                                          icon="plus">添加视频</a-button>
                                <div style="margin-top: 15px; width: 100%">
                                    <a-row v-for="item in Model.Video"
                                           :key="item.VideoID"
                                           :gutter="[10, 10]"
                                           style="border-bottom: 1px solid #f9f9f9">
                                        <a-col :span="5">
                                            <img :src="item.Image"
                                                 style="object-fit: cover"
                                                 width="80" />
                                        </a-col>
                                        <a-col :span="3">
                                            <a-button size="small"
                                                      type="danger"
                                                      @click="deleteVideo(item.VideoID)"
                                                      :disabled="disabled">删除</a-button>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-tab-pane>

                            <a-tab-pane key="6" tab="商品" style="padding: 20px" v-if="Productdisplay">
                                <a-button @click="showProduct()"
                                          :disabled="disabled"
                                          icon="plus">添加商品</a-button>
                                <div style="margin-top: 15px; width: 100%">
                                    <a-row v-for="item in Model.Product"
                                           :key="item.ProductID"
                                           :gutter="[10, 10]"
                                           style="border-bottom: 1px solid #f9f9f9">
                                        <a-col :span="5">
                                            <img :src="item.ImagePath"
                                                 style="object-fit: cover"
                                                 width="80" />
                                        </a-col>
                                        <a-col :span="16"><strong>{{ item.Name }}</strong></a-col>
                                        <a-col :span="3">
                                            <a-button size="small"
                                                      type="danger"
                                                      @click="deleteProduct(item.ProductID)"
                                                      :disabled="disabled">删除</a-button>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-tab-pane>
                        </a-tabs>
                    </a-form-model>

                </a-col>
            </a-row>

            <a-modal v-model="mapModelDisplay"
                     title="选择活动区域"
                     centered
                     okText="确定"
                     cancelText="取消"
                     width="900px"
                     height="700px"
                     @ok="confirmAddress">
                <div style="width: 100%; height: auto">
                    <div id="map_title">
                        请以当前
                        <a href="javascript:void(0)"
                           onclick="map_location();"
                           style="color: red; font-weight: 400; font-size: 20px">您的位置</a>为中心，选择本次活动范围。
                        <br />
                        <span>备注：可拖动红色中心标示，改变您当前位置</span>
                    </div>
                    <div id="map_sel">
                        <a-select :default-value="circlePath.radius"
                                  style="width: 120px"
                                  @change="radiusChange">
                            <a-select-option value="0">请选择活动区域</a-select-option>
                            <a-select-option value="5000">5公里</a-select-option>
                            <a-select-option value="10000">10公里</a-select-option>
                            <a-select-option value="15000">15公里</a-select-option>
                            <a-select-option value="20000">20公里</a-select-option>
                            <a-select-option value="30000">30公里</a-select-option>
                            <a-select-option value="50000">50公里</a-select-option>
                            <a-select-option value="100000">100公里</a-select-option>
                            <!-- <a-select-option value="-1">不限制</a-select-option> -->
                        </a-select>
                    </div>
                    <baidu-map class="bm-view"
                               ak="E8f3c743837925ce120eb93417f478db"
                               :center="mapModel.center"
                               :zoom="mapModel.zoom"
                               :scroll-wheel-zoom="true"
                               @ready="mapReady">
                        <bm-marker :position="mapModel.center"
                                   :dragging="true"
                                   @dragend="dragend">
                        </bm-marker>
                        <bm-circle :center="circlePath.center"
                                   :radius="circlePath.radius"
                                   :editing="circlePath.editing"
                                   stroke-color="blue"
                                   :stroke-opacity="0.5"
                                   :stroke-weight="2"></bm-circle>
                    </baidu-map>
                </div>
            </a-modal>
            <div v-if="QRCodeVisible">
                <QRCode :close="close"
                        :ID="NewID"
                        :hide="QRCodehide"
                        :QRCodeType="QRCodeType"></QRCode>
            </div>
            <div v-if="AwardPoolVisible">
                <AwardPool :hide="AwardPoolhide"
                           :IDList="Model.AwardPool"
                           :SelectType="SelectType"
                           :ID="Model.AwardPool.length == 0 ? 0 : Model.AwardPool[0].AwardPoolID"></AwardPool>
            </div>
            <div v-if="AwardPoolConfigVisible">
                <AwardPoolConfig :hide="AwardPoolConfighide"
                                 :IDList="Model.AwardPool"
                                 :SelectType="SelectType"
                                 :ID="Model.MaterialPromoteAwardConfig.AwardPoolID"></AwardPoolConfig>
            </div>
            <div v-if="VideoVisible">
                <Video :hide="Videohide"
                       :IDList="Model.Video"
                       :SelectType="VideoSelectType"
                       :ID=0></Video>
            </div>
            <div v-if="ProductVisible">
                <Product :hide="Producthide"
                         :IDList="Model.Product"
                         :SelectType="ProductSelectType"
                         :ID=0></Product>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
</script>
<script>
    import Rich from "@/components/RichEditor.vue";
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import yjapp from "@/Plugin/yjapp2.js";
    import local from "@/Plugin/local.js";
    import BaiduMap from "vue-baidu-map/components/map/Map.vue";
    import { BmCircle } from "vue-baidu-map";
    import { BmMarker } from "vue-baidu-map";
    import QRCode from "@/Views/MaterialModule/components/QRCode";
    import AwardPool from "@/Views/MaterialModule/components/AwardPool";
    import AwardPoolConfig from "@/Views/MaterialModule/components/AwardPool";
    import Video from "@/Views/MaterialModule/components/Video";
    import Product from "@/Views/MaterialModule/components/Product";
   
    export default {
        name: "H5",
        data() {
            return {
                phoneSize:100,
                showmb: false,
                tableData: [],
                current: 1,
                thispage:0,
                clear:false,
                total: 0,
                defaultPageSize: 5,
                showTotal: (total) => `共 ${total} `,
                tmplid: 0,
                isregselect: 0,
                Model: {
                    fileList: [],
                    Name: "",
                    Author: "",
                    Contents: "",
                    Summary: "",
                    ShareTime: "",
                    AwardPool: [],
                    MaterialAward: {
                        BeginTime: "",
                        EndTime: "",
                        GPSLimit: false,
                        ReadSecond: 1,
                        ReadMoney: 0,
                        PersonMaxMoney: 0,
                        BudgetMoney: 0,
                        Lat: 36.062233,
                        Lng: 114.383176,
                        Radius: 10,
                        CenterPointAddress:
                            "以【 河南省安阳市文峰区弦歌大道 】为中心方圆【 10 】公里",
                        MaterialID: "",
                        Name: "",
                        CustomID: "",
                    },
                    MaterialPromoteAwardConfig: {
                        BeginTime: "",
                        EndTime: "",
                        ID: 0,
                        MaterialID: "",
                        AwardType: 0,
                        LuckMoney: 0,
                        AwardPoolID: 0,
                        MaterialAwardPool: [],
                        GetAwardCount: 0,
                        BuyGoodsCount: 0,
                        ReadCount: 0,
                        AwardCount: 1,
                    },
                    Video: [],
                    Product: [],
                },
                Topic: {
                    ID: 10,
                },
                Img: "",
                endOpen: false,
                mapModelDisplay: false,
                circlePath: {
                    center: {
                        lng: 114.383176,
                        lat: 36.062233,
                    },
                    radius: "10000",
                    editing: false,
                },
                mapModel: {
                    center: { lng: 114.383176, lat: 36.062233 },
                    zoom: 13,
                    tempaddress: "以【 河南省安阳市文峰区弦歌大道 】为中心方圆【 10 】公里",
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 12 },
                        sm: { span: 12 },
                    },
                    wrapperCol: {
                        xs: { span: 25 },
                        sm: { span: 24 },
                    },
                    labelColline: {
                        xs: { span: 12 },
                        sm: { span: 12 },
                    },
                    wrapperColline: {
                        xs: { span: 12 },
                        sm: { span: 12 },
                    },
                },
                H5Value: "",
                H5Type: -1,
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: "",
                },
                QRCodeVisible: false,
                NewID: "",
                rules: {
                    ShareTime: [
                        { message: "请选择分享时间", required: true, trigger: "blur" },
                    ],
                    Name: [{ message: "请输入标题", required: true, trigger: "blur" }],
                    Author: [{ message: "请输入作者", required: true, trigger: "blur" }],
                    Summary: [
                        { message: "请输入分享描述", required: true, trigger: "blur" },
                    ],
                },
                fileList: [],
                TabsKey: "0",
                QRCodeType: 0,
                Material: {},
                MaterialList: [],
                AwardPoolVisible: false,
                AwardPoolConfigVisible: false,
                PromoteendOpen: false,
                SelectType: "checkbox",
                loading: false,
                color: "#000000",
                fontweight: false,
                fontstyle: false,
                underline: false,
                linethrough: false,
                FontList: [],
                fontname: "",
                fontthumb: "",
                fontsize: 0,
                imgwidth: 0,
                imgheight: 0,
                AwardPooldisplay: false,
                Videodisplay: false,
                VideoVisible: false,
                VideoSelectType: "checkbox",
                Productdisplay: false,
                ProductVisible: false,
                ProductSelectType: "checkbox",
            };
        },
        props: {
            //组件属性
            close: {
                type: Function,
                default: null,
            },
            ID: String,
            PushID: String,
            IDType: Number,
            disabled: Boolean,
        },
        methods: {
            //方法
            changeSize:function(val){
 
              this.val = val;
        
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialTemplateList",
                    data: {
                        MaterialType: 3,
                        PageCount: self.defaultPageSize,
                        pageIndex: self.current,
                        PushID: self.PushID,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.total = data.data.Total;
                        self.defaultPageSize = data.data.RowCount;
                        self.current =self.thispage = data.data.PageIndex;
                        if (
                            data.data.Data.length > 0 &&
                            self.current == 1 &&
                            self.tmplid == 0
                        ) {
                            self.ShowIframe(data.data.Data[0]);
                        }
                    },
                };
                http.Post(op);
            },
            Getsrc: function (info) {
                return info.PreViewImg.replace(/upload\//g, "upload2/");
            },
            GetFontsrc: function (info) {
                return "js2/fonts/" + info;
            },
            TimeStamptoDateTime: function (e) {
                return util.TimeStamptoDateTime(e, "yyyy-MM-dd hh:mm:ss");
            },
            pagerChange: function (item) {
                var self = this;
                self.current = self.thispage= item;
                local.setlocal("page",item);
                self.getTableData();
            },
            ShowIframe(e) {
                var self = this;
                self.tmplid = e.ID;
                self.Model.Name = e.Name;
                self.Model.Summary = e.Summary;
                self.Model.Contents = self.Model.Name;
                self.Model.MaterialAward.Name = self.Model.Name;
                self.H5Type = -1;
                var ImgTopic = e.PreViewImg;
                if (ImgTopic.indexOf("upload2") < 0) {
                    ImgTopic = ImgTopic.replace(/upload\//g, "upload2/");
                }
                self.Model.fileList = [
                    {
                        name: ImgTopic,
                        response: ImgTopic,
                        status: "done",
                        uid: "0",
                        url: ImgTopic,
                    },
                ];
                self.Img = ImgTopic;
            },
            GetIframe: function () {
                var self = this;
                var rt = "#";
                if (self.ID == 0) {
                    if (self.tmplid != 0) {
                        rt = "/MarketingModule/M2/Console?tmplid=" + self.tmplid;
                    }
                } else {
                    rt = "/MarketingModule/M2/Console?mid=" + self.ID;
                }
                return rt;
            },
            Addtext: function () {
                $YJApp.addtext();
            },
            Addimg: function () {
                $YJApp.addimg();
            },
            handleChange(info) {
                var self = this;
                let fileList = [...info.fileList];
                fileList = fileList.slice(-2);
                fileList = fileList.map(file => {
                    if (file.response) {
                        file.url = file.response.url;
                    }
                    return file;
                });
                //$YJApp.addimg();
                //console.log(info.file.response);
                if (info.file.status === "done") {
                    $YJApp.addimg();
                    info.file.url = info.file.response;
                    self.H5Value = info.file.url;
                    self.applydata();

                }
                self.H5Type = 2;
                self.fileList = info.fileList;
            },

            SubmitModel(formName, SubmitType) {
                var self = this;
                self.QRCodeType = SubmitType;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (
                            self.checkAwardConfig() &&
                            self.checkAward() &&
                            self.checkShareTime() &&
                            self.checkImage()
                        ) {
                            self.loading = true;
                            (function (imgpath) {
                                var op = {
                                    url: "/MarketingModule/Material/EditMaterial",
                                    data: {
                                        ID: self.NewID == "" ? self.ID : self.NewID,
                                        Name: self.Model.Name,
                                        Author: self.Model.Author,
                                        Summary: self.Model.Summary,
                                        Content: self.GetContent(imgpath),
                                        MaterialType: 3,
                                        Topic: self.Topic,
                                        Logo: self.Img,
                                        TemplateID: self.tmplid,
                                        MaterialAward: self.Model.MaterialAward,
                                        ShareTime: self.Model.ShareTime,
                                        AwardPool: self.Model.AwardPool,
                                        Video: self.Model.Video,
                                        Product: self.Model.Product,
                                        MaterialPromoteAwardConfig:
                                            self.Model.MaterialPromoteAwardConfig,
                                    },
                                    OnSuccess: function (response) {
                                        console.log(formName + response);
                                        self.savedata(response.data);
                                        self.NewID = response.data;
                                        self.loading = false;
                                        if (!self.disabled) {
                                            self.$message.success("保存成功");
                                        }
                                        self.QRCodeVisible = true;
                                    },
                                };
                                http.Post(op);
                            })(self.tableData[0].PreViewImg || "");
                        } else {
                            return false;
                        }
                    }
                });
            },
            checkShareTime: function () {
                var rt = true;
                var self = this;
                if (self.Model.ShareTime == "") {
                    rt = false;
                    self.$message.error("请选择分享时间！");
                }
                //if (Date.parse(self.Model.ShareTime) < new Date()) {
                //    rt = false;
                //    self.$message.error("请选择以后的时间！");
                //}
                return rt;
            },
            checkAward: function () {
                var rt = true;
                var self = this;
                if (
                    self.Model.MaterialAward.ReadMoney > 0 ||
                    self.Model.MaterialAward.PersonMaxMoney > 0 ||
                    self.Model.MaterialAward.BudgetMoney > 0
                ) {
                    if (
                        self.Model.MaterialAward.BeginTime == "" &&
                        self.Model.MaterialAward.EndTime == ""
                    ) {
                        rt = false;
                        self.$message.error("请选择起止时间！");
                    }
                }
                return rt;
            },
            checkAwardConfig: function () {
                var rt = true;
                var self = this;
                if (
                    self.Model.MaterialPromoteAwardConfig.GetAwardCount > 0 ||
                    self.Model.MaterialPromoteAwardConfig.BuyGoodsCount > 0 ||
                    self.Model.MaterialPromoteAwardConfig.ReadCount > 0
                ) {
                    if (
                        self.Model.MaterialPromoteAwardConfig.BeginTime == "" &&
                        self.Model.MaterialPromoteAwardConfig.EndTime == ""
                    ) {
                        rt = false;
                        self.$message.error("请选择起止时间！");
                    } else {
                        if (
                            self.Model.MaterialPromoteAwardConfig.AwardType == 0 &&
                            self.Model.MaterialPromoteAwardConfig.LuckMoney == 0
                        ) {
                            rt = false;
                            self.$message.error("请输入奖励金额！");
                        } else {
                            if (
                                self.Model.MaterialPromoteAwardConfig.AwardType == 1 &&
                                self.Model.MaterialPromoteAwardConfig.AwardPoolID == 0
                            ) {
                                rt = false;
                                self.$message.error("请选择优惠券！");
                            }
                        }
                    }
                }
                return rt;
            },
            GetContent: function (ImgTopic) {
                var self = this;
                var Content = {
                    ID: self.ID,
                    ContextText: self.Model.Name,
                    ImgTopic: ImgTopic,
                };
                return Content;
            },
            loadData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialInfo",
                    data: {
                        id: self.ID,
                    },
                    OnSuccess: function (data) {
                        self.Model.Author = data.data.Author;
                        self.Model.MaterialAward.CustomID = data.data.MaterialAward.CustomID;
                        self.Model.ShareTime = util.formatDate3(new Date());
                        if (self.ID != "0") {
                            self.Model.Name = data.data.Name;
                            self.Model.Summary = data.data.Summary;
                            self.Model.Contents = data.data.Content.ContextText;
                            var ImgTopic = data.data.Logo;
                            if (ImgTopic.indexOf("upload2") < 0) {
                                ImgTopic = ImgTopic.replace(/upload\//g, "upload2/");
                            }
                            if (ImgTopic != "" && ImgTopic != null) {
                                self.Model.fileList = [
                                    {
                                        name: ImgTopic,
                                        response: ImgTopic,
                                        status: "done",
                                        uid: "0",
                                        url: ImgTopic,
                                    },
                                ];
                                self.Img = ImgTopic;
                            } else {
                                self.Model.fileList = [];
                            }
                            self.tmplid = data.data.TemplateID;
                            if (
                                data.data.MaterialAward.BeginTime != "/Date(-62135596800000)/"
                            ) {
                                self.Model.MaterialAward.BeginTime = util.TimeStamptoDateTime(
                                    data.data.MaterialAward.BeginTime,
                                    "yyyy-MM-dd hh:mm:ss"
                                );
                            }
                            if (data.data.MaterialAward.EndTime != "/Date(-62135596800000)/") {
                                self.Model.MaterialAward.EndTime = util.TimeStamptoDateTime(
                                    data.data.MaterialAward.EndTime,
                                    "yyyy-MM-dd hh:mm:ss"
                                );
                            }
                            self.Model.MaterialAward.GPSLimit =
                                data.data.MaterialAward.GPSLimit == 1 ? true : false;
                            if (self.Model.MaterialAward.GPSLimit == 1) {
                                if (data.data.MaterialAward.Radius == -1) {
                                    self.circlePath.radius = data.data.MaterialAward.Radius.toString();
                                } else {
                                    self.circlePath.radius = (
                                        data.data.MaterialAward.Radius * 1000
                                    ).toString();
                                }
                                self.circlePath.center.lat = data.data.MaterialAward.Lat;
                                self.circlePath.center.lng = data.data.MaterialAward.Lng;
                                self.mapModel.center.lat = data.data.MaterialAward.Lat;
                                self.mapModel.center.lng = data.data.MaterialAward.Lng;
                                self.mapModel.tempaddress =
                                    data.data.MaterialAward.CenterPointAddress;
                            }
                            self.Model.MaterialAward.ReadSecond =
                                data.data.MaterialAward.ReadSecond;
                            self.Model.MaterialAward.ReadMoney =
                                data.data.MaterialAward.ReadMoney;
                            self.Model.MaterialAward.PersonMaxMoney =
                                data.data.MaterialAward.PersonMaxMoney;
                            self.Model.MaterialAward.BudgetMoney =
                                data.data.MaterialAward.BudgetMoney;
                            self.Model.MaterialAward.MaterialID =
                                data.data.MaterialAward.MaterialID;
                            self.Model.MaterialAward.Name = data.data.MaterialAward.Name;
                            self.Model.ShareTime = util.TimeStamptoDateTime(
                                data.data.ShareTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                            self.Model.AwardPool = data.data.AwardPool;
                            self.Model.Video = data.data.Video;
                            self.Model.Product = data.data.Product;
                            self.Model.MaterialPromoteAwardConfig.MaterialID = self.ID;
                            if (data.data.MaterialPromoteAwardConfig != null) {
                                if (
                                    data.data.MaterialPromoteAwardConfig.BeginTime !=
                                    "/Date(-62135596800000)/"
                                ) {
                                    self.Model.MaterialPromoteAwardConfig.BeginTime = util.TimeStamptoDateTime(
                                        data.data.MaterialPromoteAwardConfig.BeginTime,
                                        "yyyy-MM-dd hh:mm:ss"
                                    );
                                }
                                if (
                                    data.data.MaterialPromoteAwardConfig.EndTime !=
                                    "/Date(-62135596800000)/"
                                ) {
                                    self.Model.MaterialPromoteAwardConfig.EndTime = util.TimeStamptoDateTime(
                                        data.data.MaterialPromoteAwardConfig.EndTime,
                                        "yyyy-MM-dd hh:mm:ss"
                                    );
                                }
                                self.Model.MaterialPromoteAwardConfig.ID =
                                    data.data.MaterialPromoteAwardConfig.ID;
                                self.Model.MaterialPromoteAwardConfig.AwardType =
                                    data.data.MaterialPromoteAwardConfig.AwardType;
                                self.Model.MaterialPromoteAwardConfig.LuckMoney =
                                    data.data.MaterialPromoteAwardConfig.LuckMoney;
                                self.Model.MaterialPromoteAwardConfig.AwardPoolID =
                                    data.data.MaterialPromoteAwardConfig.AwardPoolID;
                                self.Model.MaterialPromoteAwardConfig.MaterialAwardPool =
                                    data.data.MaterialPromoteAwardConfig.MaterialAwardPool == null
                                        ? []
                                        : data.data.MaterialPromoteAwardConfig.MaterialAwardPool;
                                self.Model.MaterialPromoteAwardConfig.GetAwardCount =
                                    data.data.MaterialPromoteAwardConfig.GetAwardCount;
                                self.Model.MaterialPromoteAwardConfig.BuyGoodsCount =
                                    data.data.MaterialPromoteAwardConfig.BuyGoodsCount;
                                self.Model.MaterialPromoteAwardConfig.ReadCount =
                                    data.data.MaterialPromoteAwardConfig.ReadCount;
                                self.Model.MaterialPromoteAwardConfig.AwardCount =
                                    data.data.MaterialPromoteAwardConfig.AwardCount;
                            }
                        }
                    },
                };
                http.Post(op);
            },
            disabledStartDate(startValue) {
                const endValue = this.Model.MaterialAward.EndTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            disabledEndDate(endValue) {
                const startValue = this.Model.MaterialAward.BeginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },
            map_display: function () {
                var self = this;
                self.mapModelDisplay = true;
            },
            confirmAddress: function () {
                var self = this;
                self.mapModelDisplay = false;
                //确认地理位置
            },
            radiusChange: function (value) {
                var self = this;
                self.circlePath.radius = value;
                if (value == -1) {
                    self.Model.MaterialAward.Radius = value;
                } else {
                    self.Model.MaterialAward.Radius = value / 1000;
                }
            },
            mapReady: function ({ BMap, map }) {
                console.log(map);
                var self = this;
                self.gec = new BMap.Geocoder();
                self.Model.MaterialAward.Lng = self.mapModel.center.lng;
                self.Model.MaterialAward.Lat = self.mapModel.center.lat;
            },
            dragend: function (type) {
                var self = this;
                self.circlePath.center = type.point;
                self.Model.MaterialAward.Lng = type.point.lng;
                self.Model.MaterialAward.Lat = type.point.lat;
                self.gec.getLocation(type.point, function (rs) {
                    var addComp = rs.addressComponents;
                    var trainPlace =
                        addComp.province +
                        addComp.city +
                        addComp.district +
                        addComp.street +
                        addComp.streetNumber;
                    var content = trainPlace;
                    self.mapModel.tempaddress = content;
                    self.Model.MaterialAward.CenterPointAddress = content;
                });
            },
            domOnSelect: function (item) {
                //当被选中
                var self = this;
                self.H5Value = item.val;
                switch (item.tmpl) {
                    case "tmpl-txt":
                        self.H5Type = 0;
                        self.TabsKey = "0";
                        self.fontweight = item.style["font-weight"] == "800" ? true : false;
                        self.fontstyle = item.style["font-style"] == "italic" ? true : false;
                        self.underline = item.style["text-decoration"] == "underline" ? true : false;
                        self.linethrough = item.style["text-decoration"] == "line-through" ? true : false;
                        self.fontsize = item.style['font-size'].replace("px", "");
                        self.color = item.style.color;
                        self.fontname = "";
                        self.fontthumb = "";
                        self.FontList.forEach(e => {
                            if (e.name == item.style['font-family']) {
                                self.fontname = e.name;
                                self.fontthumb = e['font-thumb'];
                            }
                        });
                        if (self.fontname == "" && self.fontthumb == "") {
                            self.fontname = self.FontList[0].name;
                            self.fontthumb = self.FontList[0]['font-thumb'];
                        }
                        break;
                    case "tmpl-html":
                        self.H5Type = 1;
                        self.TabsKey = "0";
                        break;
                    case "tmpl-img":
                        self.H5Type = 2;
                        self.fileList = [
                            {
                                name: self.H5Value,
                                response: self.H5Value,
                                status: "done",
                                uid: "0",
                                url: self.H5Value,
                            },
                        ];
                        self.TabsKey = "0";
                        self.imgwidth = parseInt(item.style["width"].replace("px", ""));
                        self.imgheight = parseInt(item.style["height"].replace("px", ""));
                        break;
                    case "tmpl-qr":
                        self.MaterialList = item.valdict;
                        self.MaterialList.forEach((e) => {
                            if (e.val == self.H5Value) {
                                self.SetShowKey(e);
                            }
                        });
                        self.H5Type = 3;
                        self.TabsKey = "0";
                        break;
                    default:
                        self.H5Type = -1;
                        break;
                }
            },
            domonready: function (app) {
                var self = this;
                if (app.hascoupon() > 0) {
                    self.AwardPooldisplay = true;
                }
                if (app.hasvideo() > 0) {
                    self.Videodisplay = true;
                }
                if (app.hasproduct() > 0) {
                    self.Productdisplay = true;
                }
            },
            applydata: function () {
                //应用预览模板数据
                var self = this;
                $YJApp.update(self.H5Value);
                if (self.H5Type == 0) {
                    $YJApp.updatestyle('color', self.color);
                    if (self.fontname == "0") {
                        $YJApp.updatestyle('font-family', null);
                    } else {
                        $YJApp.updatestyle('font-family', self.fontname);
                    }
                    if (self.fontsize >= 8 && self.fontsize <= 80) {
                        $YJApp.updatestyle("font-size", self.fontsize + "px");
                    }
                    if (self.fontweight) {
                        $YJApp.updatestyle("font-weight", "800");
                    } else {
                        $YJApp.updatestyle("font-weight", null);
                    }
                    if (self.fontstyle) {
                        $YJApp.updatestyle("font-style", "italic");
                    } else {
                        $YJApp.updatestyle("font-style", null);
                    }
                    if (self.underline) {
                        $YJApp.updatestyle("text-decoration", "underline");
                    } else {
                        if (self.linethrough) {
                            $YJApp.updatestyle("text-decoration", "line-through");
                        } else {
                            $YJApp.updatestyle("text-decoration", null);
                        }
                    }
                }
                if (self.H5Type == 2) {
                    if (self.imgwidth >= 16) {
                        $YJApp.updatestyle("width", self.imgwidth + "px");
                    }
                    if (self.imgheight >= 16) {
                        $YJApp.updatestyle("height", self.imgheight + "px");
                    }
                }
            },
            savedata: function (mid) {
                //保存模板数据
                $YJApp.save(mid);
            },
            imgChangeH5: function (info) {
                var self = this;
                if (info.file.status === "error") {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    } else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === "done") {
                    info.file.url = info.file.response;
                    self.H5Value = info.file.url;
                    self.applydata();
                }
                self.fileList = info.fileList;
            },
            imgChange: function (info) {
                var self = this;
                if (info.file.status === "error") {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    } else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === "done") {
                    info.file.url = info.file.response;
                }
                self.Model.fileList = info.fileList;
                self.Img =
                    self.Model.fileList.length > 0 ? self.Model.fileList[0].response : "";
            },
            QRCodehide: function () {
                var self = this;
                self.QRCodeVisible = false;
            },
            name_change: function (item) {
                var self = this;
                self.Model.MaterialAward.Name = item.target.value;
            },
            checkImage: function () {
                var rt = true;
                var self = this;
                if (self.Img == "") {
                    rt = false;
                    self.$message.error("请上传图片！");
                }
                return rt;
            },
            SetShowKey(e) {
                var self = this;
                var keyArray = new Array();
                keyArray.push({ key: e.key, val: e.val });
                self.Material = keyArray[0];
                self.H5Value = e.val;
                self.applydata();
            },
            showVideo: function () {
                var self = this;
                self.VideoVisible = true;
            },
            showProduct: function () {
                var self = this;
                self.ProductVisible = true;
            },
            showAwardPool: function () {
                var self = this;
                self.SelectType = "radio";
                self.AwardPoolVisible = true;
            },
            showAwardPoolConfig: function () {
                var self = this;
                self.SelectType = "radio";
                self.AwardPoolConfigVisible = true;
            },
            AwardPoolhide: function (e, info) {
                var self = this;
                if (e == 1) {
                    self.Model.AwardPool = info;
                }
                self.AwardPoolVisible = false;
            },
            Videohide: function (e, info) {
                var self = this;
                if (e == 1) {
                    self.Model.Video = info;
                }
                self.VideoVisible = false;
            },
            Producthide: function (e, info) {
                var self = this;
                if (e == 1) {
                    self.Model.Product = info;
                }
                self.ProductVisible = false;
            },
            AwardPoolConfighide: function (e, info) {
                var self = this;
                if (e == 1) {
                    if (info.length > 0) {
                        self.Model.MaterialPromoteAwardConfig.AwardPoolID =
                            info[0].AwardPoolID;
                        self.Model.MaterialPromoteAwardConfig.MaterialAwardPool = info;
                    } else {
                        self.Model.MaterialPromoteAwardConfig.AwardPoolID = 0;
                        self.Model.MaterialPromoteAwardConfig.MaterialAwardPool = [];
                    }
                }
                self.AwardPoolConfigVisible = false;
            },
            deleteAwardPoolConfig(e) {
                console.log(e);
                var self = this;
                self.Model.MaterialPromoteAwardConfig.AwardPoolID = 0;
                self.Model.MaterialPromoteAwardConfig.MaterialAwardPool = [];
            },
            deleteAwardPool(e) {
                var self = this;
                for (var i = 0; i < self.Model.AwardPool.length; i++) {
                    if (self.Model.AwardPool[i].AwardPoolID == e) {
                        self.Model.AwardPool.splice(
                            self.Model.AwardPool.indexOf(self.Model.AwardPool[i]),
                            1
                        );
                        break;
                    }
                }
            },
            deleteVideo(e) {
                var self = this;
                for (var i = 0; i < self.Model.Video.length; i++) {
                    if (self.Model.Video[i].VideoID == e) {
                        self.Model.Video.splice(
                            self.Model.Video.indexOf(self.Model.Video[i]),
                            1
                        );
                        break;
                    }
                }
            },
            deleteProduct(e) {
                var self = this;
                for (var i = 0; i < self.Model.Product.length; i++) {
                    if (self.Model.Product[i].ProductID == e) {
                        self.Model.Product.splice(
                            self.Model.Product.indexOf(self.Model.Product[i]),
                            1
                        );
                        break;
                    }
                }
            },
            Getsrc2(e) {
                return e.Pic;
            },
            disabledPromoteStartDate(startValue) {
                const endValue = this.Model.MaterialPromoteAwardConfig.EndTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            handlePromoteStartOpenChange(open) {
                if (!open) {
                    this.PromoteendOpen = true;
                }
            },
            disabledPromoteEndDate(endValue) {
                const startValue = this.Model.MaterialPromoteAwardConfig.BeginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handlePromoteEndOpenChange(open) {
                this.PromoteendOpen = open;
            },
            fontclick(e) {
                var self = this;
                switch (e) {
                    case "fontweight":
                        self.fontweight = !self.fontweight;
                        break;
                    case "fontstyle":
                        self.fontstyle = !self.fontstyle;
                        break;
                    case "underline":
                        self.underline = !self.underline;
                        self.linethrough = false;
                        break;
                    case "linethrough":
                        self.linethrough = !self.linethrough;
                        self.underline = false;
                        break;
                }
                self.applydata();
            },
            loadFontData: function () {
                var self = this;
                var op = {
                    url: "/js2/fontconfig.json",
                    OnSuccess: function (data) {
                        self.FontList = data.data["font-family"];
                    },
                };
                http.Get(op);
            },
            changefont: function (e) {
                var self = this;
                self.fontname = e.name;
                self.fontthumb = e["font-thumb"];
                self.applydata();
            },
            toggleshow: function () {

                this.showmb = !this.showmb
            }
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
            BaiduMap,
            BmCircle,
            BmMarker,
            Rich,
            QRCode,
            AwardPool,
            AwardPoolConfig,
            Video,
            Product
        },
        beforeCreate: function () {
            //生命周期，创建前
          this.current = this.thispage = local.getlocal("page")!=undefined?local.getlocal("page"):1
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            this.getTableData();
            this.loadData();
            this.loadFontData();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
            var me = this;
            !me.isregselect &&
                ((me.isregselect = 1),
                    (window.$YJApp = yjapp.$YJApp),
                    yjapp.$YJApp.regselect({
                        iframe: "MyIframe",
                        onselect: function (selectitem) {
                            me.domOnSelect(selectitem);
                        },
                        onready: function (selectitem) {
                            me.domonready(selectitem);
                        },
                    }),
                    1);
        },
        beforeDestroy: function() {
            //生命周期，销毁前
            if (!this.clear) {
                local.clearlocal();
            }
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    };

</script>
<style scoped>
.zoomBar{position: absolute;bottom:10px;right:10px;}
::-webkit-scrollbar { width: 10px; cursor: default; }
::-webkit-scrollbar { width: 10px; height: 10px; overflow: auto; }
::-webkit-scrollbar-thumb { background-color: #d2d3d4; min-height: 25px; min-width: 25px; border: 1px solid #e0e0e0; border-radius: 20px; }
::-webkit-scrollbar-track { background-color: #f2f3f4; border: 1px solid #efefef; }
.right-cont { background: #fff; height: 100%; overflow-y: auto; }
.mask { width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: rgba(255, 255, 255, 0.75); background: #f7f8f9; z-index: 9; -webkit-transition: all 0.3s; transition: all 0.3s; }
.push-info { z-index: 9; height: 100%; }
.phone-pre { text-align: center; height: 100%; display: flex; justify-content: center; align-items: center; background: #f1f3f7; }
#MyIframe { display: block; }
.phone-frame { align-items: center; width: 440px; height: 782px; margin: auto; overflow-x: hidden; text-align: left; position: relative; overflow-y: auto; background-size: 100%; background: #fff; color: #000000; display: block; box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 15px; }
.iframe-p { width: 345px; padding: 35px 25px; height: 80vh; margin: 50px auto; text-align: left; overflow-y: auto; color: #000000; }
.bm-view { width: 100%; height: 600px; }
.btn-g { background: #f5f6f7; display: inline-block; }
#map_title { position: absolute; z-index: 9999; left: 23px; right: 23px; height: 60px; background-color: #09f; padding-top: 10px; padding-left: 20px; color: white; }
#map_sel { position: absolute; z-index: 9999; right: 106px; top: 92px; }
#map_btns { position: absolute; z-index: 9999; bottom: 30px; width: 100%; }
#map_btns input[type="button"] { width: 120px; height: 45px; margin-top: 20px; color: #fff; border: none; border-radius: 30px; opacity: 0.8; }
.map_save { background-color: green; margin-right: 40px; }
.map_cancel { background-color: #f15b5b; }
.solid-btn { background: #32a9ff; color: #fff !important; padding: 4px 18px; border: none; border-radius: 2px; transition: all 0.15s ease-in-out; margin-top: -3px; }
.yj-storecon-template > div { height: 100px; width: 100px; overflow: hidden; text-align: center; flex-shrink: 0; }
::v-deep .ql-align-justify { text-align: justify; }
::v-deep .contents-p > p > img { width: 100%; object-fit: cover; }
.active { border: 1px solid #2281f8; cursor: default; color: #fff; background-color: rgba(0, 0, 0, 0.5); }
.mtoptitleCont { position: relative; height: 160px; overflow: hidden; }
.mtop-title { position: absolute; bottom: 5px; left: 5px; right: 5px; color: #fff; background-color: rgba(0, 0, 0, 0.5); text-indent: 6px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; line-height: 1.8rem; font-size: 0.74rem; display: none; }
.mtop-but { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -10px; width: 60px; height: 22px; text-align: center; line-height: 22px; color: #fff; background: #32a9ff; font-size: 12px; border-radius: 4px; display: none; }
.mtop-but:active { background-color: #1989da; }
.mtoptitleCont:hover .mtop-title, .mtoptitleCont > div.active .mtop-title { display: block; }
.mtoptitleCont > div.active .mtop-but { display: block; }
.content { min-height: 250px; padding: 15px; margin-right: auto; margin-left: auto; padding-left: 15px; padding-right: 15px; }
.content { margin: 15px 20px 15px 15px; padding-top: 0; }
.form-search { display: block; list-style-type: disc; padding-left: 0; }
ul, ol { margin-top: 0; margin-bottom: 10px; }
.box { border-top: none; box-shadow: none; }
.right-btn { margin-top: 18px; }
.line-btn { border: 1px solid #32a9ff; color: #32a9ff; padding: 7px 15px; background: #fff; transition: all 0.15s ease-in-out; }
.gray-line-btn { border: 1px solid #e5e5e5; color: #666; padding: 7px 15px; background: #fff; transition: all 0.15s ease-in-out; margin-left: 20px; }
.gray-line-btn { cursor: pointer; }
.solid-btn { background: #32a9ff; color: #fff !important; padding: 4px 18px; border: none; border-radius: 2px; transition: all 0.15s ease-in-out; margin-top: -3px; }
.solid-btn:hover { background: #1b99f5; cursor: pointer; color: #fff; }
.num { font-size: 26px; color: #32a9ff; padding: 5px; font-weight: 500; }
.inners { padding: 10px; border: 1px solid #f3f4f5; }
.search { overflow: hidden; background: #fbfbfb; margin: 10px auto; padding: 10px; }
.line-btn:hover { background: #32a9ff; color: #fff; border-radius: 2px; transition: all 0.15s ease-in-out; cursor: pointer; }
.form-search { display: block; list-style-type: disc; padding-left: 0; }
.mtb5 { margin: 5px 0; }
.mtb10 { margin: 10px 0; }
.mtb20 { margin: 20px 0; }
.tr-middle td { vertical-align: middle; }
body { background: #f3f5f5; font-family: Arial, Helvetica, sans-serif, "Microsoft YaHei", "ËÎÌå"; font-size: 15px; }
.sys-name { color: #00a2e9; margin: 0; padding: 0; font-size: 25px; font-weight: 500; height: 68px; line-height: 68px; }
.right-opt { height: 68px; line-height: 68px; cursor: pointer; }
.top-top { margin-bottom: 10px; background: #fff; border-bottom: solid 1px #e0e5e5; }
.top-center { max-width: 1160px; margin: 0 auto; display: flex; justify-content: space-between; }
.content-wrapper { background: #fff; }
.content { margin: 15px 20px 15px 15px; padding-top: 0; }
.box-header { border-bottom: 1px solid #f3f5f5; padding: 0 10px; }
.box-header > .box-title { height: 62px; line-height: 62px; }
.top-center a[href] { color: #32a9ff; }
a { color: #32a9ff; }
.pager li > a { border: none; }
.main-right-search { padding: 20px; border-radius: 4px; -moz-border-radius: 4px; background-color: #fbfbfb; padding-top: 0; }
.form-search li { float: left; overflow: hidden; margin-right: 17px; width: 350px; height: 28px; line-height: 28px; font-size: 14px; border: solid 1px #e0e5e5; border-radius: 4px; -moz-border-radius: 4px; list-style: none; margin-top: 8px; margin-bottom: 0; padding: 0; }
.form-search li label { float: left; width: 110px; color: #666; text-align: center; border-right: solid 1px #e0e5e5; }
.form-search li input { float: left; border: none; background: none; outline: none; text-indent: 6px; -webkit-appearance: none; background-color: #ffffff; width: 235px; height: 26px; color: #333333; line-height: 28px; }
.form-search-margin { margin-top: 12px; }
.form-search li .inline-text { padding-left: 5px; }
.pager { padding-left: 0; margin: 20px 0; text-align: center; list-style: none; }
.pager li { display: inline; }
.pager ul li { list-style: none outside none; }
.pager li > a { border: none; }
.pager li > a, .pager li > span { display: inline-block; padding: 5px 14px; background-color: #fff; border-radius: 15px; }
.table { width: 100%; margin-bottom: 20px; }
.fontclickcss { color: #1890ff !important; font-weight: bold; }
.FontListCss { height: 600px; overflow-y: scroll; }
.editor-item { margin: 10px 0; }
.editor-item > h4 { padding: 5px 0 }
::v-deep .el-color-picker__trigger { width: 170px; }
::v-deep .el-icon-arrow-down::before { content: ''; display: none; }
.tool-bar { width: 100%; height: 100%; border-right: 1px solid #e5e5e5; }
.tool-bar > a { display: block; color: #222; text-align: center; padding: 10px 0 }
.tool-bar > a:hover { color: #2281f8 }
</style>
